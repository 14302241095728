







































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  listUserInvoice,
  insertUserInvoice,
  updateUserInvoice,
  delUserInvoice,
  getUserInfo,
} from "@/api";
import { Form } from 'element-ui';
interface IOrder {
  id: string;
  orderId: string;
  buyingTime: string;
  model: string;
  factory: string;
  amount: number;
  price: string;
  total: string;
}
interface IInvoice {
  id?: string
  userId?: number
  unitName?: string
  taxCode?: string
  regAddress?: string
  regPhone?: string
  bank?: string
  account?: string
  receiverName?: string
  receiverPhone?: string
  receiverAddress?: string
  receiverMail?: string
  type?: number

}
@Component
export default class UserInfo extends Vue {
  currentPage = 1;
  pageSize = 5;
  invoiceList: Array<IInvoice> = [];
  showInvoiceDialog = false;

 rules = {
    unitName: [{ required: true, message: '请输入开票公司名称', trigger: 'blur' }],
    regAddress: [{ required: true, message: '请输入公司注册地址', trigger: 'blur' }],
    regPhone: [{ required: true, message: '请输入公司电话', trigger: 'blur' }],
    taxCode: [{ required: true, message: '请输入税务登记号', trigger: 'blur' }],
    bank: [{ required: true, message: '请输入开户银行', trigger: 'blur' }],
    account: [{ required: true, message: '请输入开户银行账号', trigger: 'blur' }],
    receiverName: [{ required: true, message: '请输入收票人', trigger: 'blur' }],
    receiverPhone: [{ required: true, message: '请输入收票人手机号', trigger: 'blur' }],
    receiverAddress: [{ required: true, message: '请输入寄送详细地址', trigger: 'blur' }],
  }

//mobile手机，phone座机
  invoice : IInvoice = {
    id: "",
    userId: 0,
    unitName: "",
    taxCode: "",
    regAddress: "",
    regPhone:"",
    bank:"",
    account:"",
    receiverName:"",
    receiverPhone:"",
    receiverAddress:"",
  };
  userInfo = {
    isVip : 0,
    companyId : "0",
    userId : 0,
  };
  mounted(): void {
    // this.fetchOrderList();
    this.getUserInfo();
    // this.listUserAddress();
  }
getUserInfo(): void{
  getUserInfo({}).then(res => {
    if(res.status == 200){
        if(res.data.code == 200){
          this.userInfo = res.data.user
          window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          this.listUserInvoice();
        }else{
          this.$message.error(res.data.msg );
        }
      }else{
        this.$message.error(res.data.statusText);
      } 
    }).catch(() => {
      console.log(' error ')
    })
};
  tableRowClassName(row:any)   {
    // console.log(row ,rowIndex,"=====");
        if (row.row.isMain == "1") {
          return 'success-row';
        } 
         return '';
      };
  


  insertOrUpdate():void {
    if(this.invoice.id){
      this.updateUserInvoice();
    }else{
      this.insertUserInvoice();
    }
  }

  //发票列表
  listUserInvoice(): void {
  
    listUserInvoice("special")
      .then((res) => {
        if (res.status == 200) {
          if (res.data.code == 200) {
            console.log(res.data.data)
            this.invoiceList = res.data.data;
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }
  //新增
  insertUserInvoice(): void {
    this.invoice.id = "";
    let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    let data = {
      userId: userInfo.userId,
      unitName: this.invoice.unitName,
      taxCode: this.invoice.taxCode,
      regAddress: this.invoice.regAddress,
      regPhone: this.invoice.regPhone,
      bank: this.invoice.bank,
      account : this.invoice.account,
      receiverName : this.invoice.receiverName,  
      receiverPhone : this.invoice.receiverPhone,
      receiverAddress:this.invoice.receiverAddress
    };
    insertUserInvoice(data)
      .then((res) => {
        // console.log(res)
        if (res.status == 200) {
          if (res.data.code == 200) {
            // console.log(res)
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
            this.$message.success("添加成功");
            this.showInvoiceDialog = false;
            this.listUserInvoice();
          } else {
            this.$message.warning(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        this.$message.error("服务器出了点小问题,工程师正在玩命抢修中");
      });
  }
  //修改
  updateUserInvoice(): void {
    
     
    let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    let data = {
      id: this.invoice.id,
      userId: userInfo.userId,
      unitName: this.invoice.unitName,
      taxCode: this.invoice.taxCode,
      regAddress: this.invoice.regAddress,
      regPhone: this.invoice.regPhone,
      bank: this.invoice.bank,
      account : this.invoice.account,
      receiverName : this.invoice.receiverName,  
      receiverPhone : this.invoice.receiverPhone,
      receiverAddress:this.invoice.receiverAddress
    };
    
    updateUserInvoice(data)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.code == 200) {
            console.log(res);
            this.$message.success("修改成功");
            this.showInvoiceDialog = false;
            this.listUserInvoice();
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }
  //删除
  delUserInvoice(index: any, row: any): void {
    console.log(index, "==========", row);
    this.$confirm("删除后不可恢复,确定对该发票进行删除操作吗 ?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        ///////删除请求
          delUserInvoice(row.id)
            .then((res) => {
              
              if (res.status == 200) {
                if (res.data.code == 200) {
                  this.$message.success("删除成功");
                  this.listUserInvoice();
                  // this.userInfo = res.data.user
                  // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
                } else {
                  this.$message.error(res.data.msg);
                }
              } else {
                this.$message.error(res.data.statusText);
              }
            })
            .catch(() => {
              console.log(" error ");
            });
      })
      ///////删除请求
      .catch(() => {console.log(" error ");});

 
  }
  validateForm(): void {
    ;(this.$refs['invoiceForm'] as Form).validate((valid: boolean) => {
      if (valid) {
        // this.submitInvoice()
        this.insertOrUpdate();
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }

  edit(item: IInvoice): void {
    this.invoice = item
    this.showInvoiceDialog = true
  }


  checkTransportation(a: number, b: IOrder): void {
    console.log(a, b);
  }

  applyInvoice(a: number, b: IOrder): void {
    console.log(a, b);
  }

  changeProduct(a: number, b: IOrder): void {
    console.log(a, b);
  }

  handleSizeChange(val: number): void {
    this.pageSize = val;
    this.currentPage = 1;
  }

  handleCurrentChange(val: number): void {
    this.currentPage = val;
  }
}
